/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Fullmap, FullmapWrap, FullmapCover, ContactForm, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--parallax pb--80 pt--80" name={"kontakt"} style={{"backgroundColor":"rgba(248,248,248,1)"}} parallax={true} css={css`
      background-position: 50% 78%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"Kontakt<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"54ten8dkh78"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":156}} srcSet={"https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box" content={"Pavel Magda&nbsp;"}>
              </Title>

              <Text className="text-box mt--10" style={{"maxWidth":650}} content={"Specialista – zákaznický servis"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":650}} content={" +420 777 206 285"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/10513/8be6492cc59e42ad8018c35ba4a36df3_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":124}} srcSet={"https://cdn.swbpg.com/t/10513/8be6492cc59e42ad8018c35ba4a36df3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/8be6492cc59e42ad8018c35ba4a36df3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/8be6492cc59e42ad8018c35ba4a36df3_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box" content={"Miroslav Novák"}>
              </Title>

              <Text className="text-box mt--10" style={{"maxWidth":650}} content={"Oblastní zástupce"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":650}} content={"+420 603 269 051"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":156}} srcSet={"https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box" content={"Jitka Magdová"}>
              </Title>

              <Text className="text-box mt--10" style={{"maxWidth":650}} content={"Ekonomický úsek"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":650}} content={"  +420 602 426 647"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"ahepje34qn4"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"Brno"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="css-12vzq2n css-eivkvl --parallax pb--80 pt--80" name={"formular"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=3000x_.jpg);
    }
  
background-position: 50% 78%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Napište nám</span><br>"}>
              </Title>

              <ContactForm className="--shape2 --style1 mt--25" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3 flex--center" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500" content={"<span style=\"color: rgb(255, 255, 255);\">Flow Interiér</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":224}} content={"<span style=\"color: rgb(220, 221, 221);\">Výrobce kvalitních kuchyní a nábytku na míru pro Vaši spokojenost. Realizujeme  po Praze, Středočeském kraji i okolí.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":473}} content={"<span style=\"color: rgb(248, 248, 248);\">IČ 25731491<br>DIČ CZ25731491<br><br>Společnost je vedená u Městského soudu v Praze, spisová značka C 65152</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500 lh--14" content={"<span style=\"color: var(--color-dominant);\">+420 777 206 285<br>info@living4handy.cz<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: rgb(220, 221, 221);\">Living4Handy s.r.o.\n<br>Luženská 2801<br>&nbsp;Rakovník</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}